<script>
export default {
  render() {
    return this.$scopedSlots.default({
      hasMultipleExams: this.hasMultipleExams,
      force: this.forceAutoselect,
    })
  },

  props: {
    list: Array,
    examID: Number
  },

  computed: {
    hasMultipleExams() {
      return this.list && this.list.length > 1 ? true : false
    },
  },

  methods: {
    forceAutoselect(list) {
      this.$emit('forceAutoselect', list, list[0].ID)
    }
  },

  watch: {
    list(newList) {
      if(newList.length && !this.examID) {
        return this.$emit('autoselect', newList[0].ID)
      } 
    }
  }
}
</script>